import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

const SEO = ({
  title,
  description = '',
  image = '',
  facebookImage = image,
  twitterImage = image,
  lang = 'en',
  meta = [],
}) => {
  const { site, markdownRemark } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          favicon
          schemaLogo
          facebookImage
          twitterImage
        }
      }
      markdownRemark(fileAbsolutePath: { regex: "/settings/site-meta.md/" }) {
        frontmatter {
          title
          description
          url
          socials {
            name
            user
            url
          }
        }
      }
    }
  `);

  const siteMeta = Object.assign(site.siteMetadata, markdownRemark.frontmatter);

  const siteTitle = siteMeta.title;
  const siteLogo = siteMeta.schemaLogo;
  const siteUrl = siteMeta.url;

  const location = useLocation();
  const canonical = siteUrl + (location.pathname || '');

  description = description || siteMeta.description;
  facebookImage = facebookImage || siteMeta.facebookImage;
  twitterImage = twitterImage || siteMeta.twitterImage;

  const { user: twitterAuthor } =
    siteMeta.socials.find(({ name }) => name == 'Twitter') || {};

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={siteTitle ? `%s | ${siteTitle}` : null}
      link={[
        { rel: 'icon', type: 'image/png', href: siteMeta.favicon },
        { rel: 'canonical', href: canonical },
      ]}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: siteUrl + facebookImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        ...(twitterAuthor
          ? [
              {
                name: 'twitter:creator',
                content: siteMeta.author || '',
              },
            ]
          : []),
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image',
          content: siteUrl + twitterImage,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'SoftwareApplication',
          applicationCategory: 'MultimediaApplication',
          applicationSubCategory: 'Video Editing',
          name: siteTitle,
          installUrl:
            'https://apps.apple.com/us/app/splice-video-editor-maker/id409838725',
          operatingSystem: 'iOS 13,iPadOS 13',
          aggregateRating: {
            ratingCount: 221700,
            ratingValue: '4.6',
          },
          offers: {
            '@type': 'Offer',
            price: '0',
            priceCurrency: 'USD',
          },
          url: siteUrl,
          image: siteUrl + siteLogo,
          sameAs: siteMeta.socials.map(({ url }) => url),
        })}
      </script>
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  facebookImage: PropTypes.string,
  twitterImage: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
